.leaderboardContainer {
    margin-top: 60px;
    margin-bottom: 100px;
    text-align: center;
  }

  .leaderboardContainer ol li {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .titleShort {
    margin-top: 20px;
  }

  .explainer {
    margin-top: 30px;
  }

  .explainer-padded {
    padding-left: 40px;
  }

  .leaderBoardConfigForm {
    margin-top: 70px;
    border: 2px solid #d33438;
    border-radius: 6px;
    padding: 24px;
    text-align: initial;
  }

  .newLdBoard {
    border: 2px solid #000;
  }

  .leaderBoardConfigFormSubmit {
    margin-top: 10px;
  }

  .inputFieldReadOnlyText {
    font-weight: bold;
  }