html, body {
    max-width: 100%;
    overflow-x: hidden;
    font-family: Inter;
    height: 100%;
}

.root {
    height: 100%;
}

h1, h2 {
    font-family: Inter;
    font-weight: bold;
    text-align: center;
}

a {
    text-decoration: none;
    color: blue;
    font-weight: bold;
}

.hidden {
    display: none !important;
}

button, .linkButton {
    font-family: Inter;
    font-size: 1rem;
    border-radius: 6px;
    background-color: #000;
    color: #fff;
    border: 1px solid #000;
    outline: none;
    padding: 0.5rem 2rem;
    cursor: pointer;
    text-decoration: none;
}

button:hover, .linkButton:hover {
    background-color: #fff;
    color: #000;
}

.secondaryButton {
    background: #fff;
    color: #d33438;
    border: 1px solid #d33438;
}

.secondaryButton:hover {
    background: #d33438;
    color: #fff;
    border: 1px solid #d33438;
}

.swpage {
  text-align: center;
  height: 100%;
}


/* inputs and form fields */

input[type=text], input[type=password], input[type=tel], select {
    width: 374px;
    height: 48px;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    box-sizing: border-box;
    color: #000;
    border: 1px solid #ccc;
    border-radius: 3px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #E8E8EB;
    opacity: 1; /* Firefox */
  }
  
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #E8E8EB;
}

::-ms-input-placeholder {
    color: #E8E8EB;
}

select {
    padding: 12px;
}

.textInputField, .dropdownField {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    height: 64px;
}
  
.textInputField .inputFieldLabel, .dropdownFieldLabel {
    flex: 2;
}

.textInputField .inputFieldTextInput, .dropdownSelect {
    flex: 3;
}

.textInputField.inputFieldReadOnlyText {
    flex: 3;
}

/**
.dropdownField {
    display: grid;
    width: 80%;
    grid-template-columns: 32% 68%;
    grid-template-rows: 100%;
    align-items: baseline;
    grid-column-gap: 30px;
  }
  
  .dropdownField .dropdownFieldLabel {
    grid-column: 1;
    grid-row: 1;
  }
  
  .dropdownField {
    grid-column: 2;
    grid-row: 1;
    margin-bottom: 20px;
    margin-left: 80px;
  }

  .dropdownSelect {
    width: 387px;
  }
  */

  .checkboxRow {
    width: 100%;
    padding-left: 20%;
  }
  
  .checkboxField {
    text-align: center;
  }
  
  .checkboxLabel {
    flex: 1 0 420px;
    max-width: 420px;
    text-align: left;
    margin-left: 8px;
  }

.checkBox {
    display: block;
    width: 400px;
    margin: auto;
  }
  
  .checkboxContainer {
    display: block;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .checkboxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border: solid rgba(170,57,57, 0.2); /*white;*/
    border-width: 1px;
  }
  
  /* On mouse-over, add a grey background color */
  .checkboxContainer:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkboxContainer input:checked ~ .checkmark {
    background-color: #AA3939; /*#2196F3;*/
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkboxContainer input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkboxContainer .checkmark:after {
    left: 10px;
    top: 5px;
    width: 5px;
    height: 13px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  @media only screen and (max-width: 600px) {
    .checkboxRow {
      padding-left: 0px !important;
    }
  }

  

.textArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /*padding: 12px 20px;*/
    /*margin: 8px 0;*/
}

.textAreaLabel {
    flex: 2 1;
    font-size: 18px;
    color: #000;
}

.textAreaTextInput {
    flex: 3 1;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    box-sizing: border-box;
    color: #000;
    border: 1px solid #ccc;
    border-radius: 3px;
}

label {
    margin-right: 1rem;
}

/* media queries */

/*
@media only screen and (max-width: 1000px) {
    .textInputField {
        flex-direction: ;
    }
}*/

/* General styles */

.centered {
    text-align: center;
}

.marginned {
    margin-top: 24px;
}

.basicContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;
    width: 800px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 100px;
}

.featureContainer {
  text-align: center;
}

.confirmationContainer {
    margin-top: 0;
    margin-bottom: 260px;
}

.quickConfirmationContainer {
    margin-top: 180px;
    margin-bottom: 220px;
}

.row {
    display: flex;
    /*flex-wrap: wrap;*/
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

.label {
  flex: 1 0 100px;
  max-width: 220px;
  margin-bottom: 0px;
  text-align: right;
}
  
.field {
  flex: 1 0 200px;
  max-width: 320px;
}

.explainer {
    color: #000;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 27px;
    font-weight: normal;
}

.successful {
  color: green;
}

.exceptions {
    color: #d33438;
    text-align: center;
    margin-top: 12px;
  }

/**pre {
  background: #f3f3f7;
  border: 1px solid #dedee3;
  padding: 11px;
  font-size: 12px;
  line-height: 1.3em;
  margin-top: 22px;
  margin-bottom: 22px;
  overflow: auto;
  text-align: left;
  width: 100%;
 }*/
 
 pre.pre-text-left {
   margin-left: 0px;
   text-align: left;
 }

/* Footer */

.footer {
    /*position: absolute;
    bottom: 0;*/
    background: #fff;
    color: #000;
    margin-top: 2rem;
    margin-left: 4rem;
    margin-right: 4rem;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding: 50px 36px;
 }

 .footerContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
 }

 .footerTextContentItem {
     margin: 0 12px;
 }

.footerLink {
    color: #000;
    text-decoration: none;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
}

.twitterLogo {
    width: 30px;
    height: 30px; 
    margin-top: 5px;
}

.footerCopyright {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
}

.footerLogo {
    width: 198px;
    height: 37.5px;
 }

 .formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 }

 .itemList {
  text-align: left;
}

 @media only screen and (max-width: 1200px) {

    .miniTitle {
      padding-left: 20px;
    }
  
    .screenshotMedium, .onlyLargeScreen {
      display: none;
    }
  }

 @media only screen and (max-width: 1000px) { 

    .formContainer, .basicContainer {
        width: 600px;
        margin: auto;
      }

    .footer {
        flex-direction: column;
        gap: 1.5rem;
        margin-top: 2rem;
        padding: 30px 36px;
    }

    .footerContent {
        order: 1;
    }

    .footerCopyright{
        order: 3;
    }

    .footerLogoDiv {
        order: 2;
    }
 }
  
  @media only screen and (max-width: 600px) {
    .checkboxRow {
      padding-left: 0px !important;
    }
  
    .formContainer, .basicContainer {
      width: 400px;
    }
  }