.modalPre {
    max-height: 220px;
    overflow-y: scroll;
}

.main {
    background-color: #F6F8FA;
    width: auto;
    height: 100%;
    min-height: 80vh;
    margin-left: 16rem;
    position: relative;
    padding-top: 40px;
    overflow: hidden;
    /*padding-bottom: 310px;*/
    z-index: 0;
    text-align: center;
}

.comingSoon {
    background: #FFFFFF;
    box-shadow: 0px 1px 19px rgba(0, 0, 0, 0.09);
    margin-left: 89px;
    margin-right: 89px;
    padding:  217px; /*41px;*/
    margin-bottom: 241px;
    text-align: center;
}

.loaderSpinner {
    text-align: center;
    margin-left: -35px;
    margin-top: 60px;
}

.noContent {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.dashboardList {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 95%;
}

.dashboardListHeader, .dashboardListRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}

.dashboardListHeaderElem {
    flex: 1;
    background-color: #767271;
    color: #fff;
    font-weight: bold;
    border: 1px solid #fff;
}

.dashboardListRowElem {
    flex: 1;
    border: 1px solid #767271;
}

.dashboardListHeaderElem.veryLongElem, .dashboardListRowElem.veryLongElem {
    flex: 1.8;
}

.dashboardListHeaderElem.longElem, .dashboardListRowElem.longElem {
    flex: 1.4;
}

.dashboardListHeaderElem.mediumElem, .dashboardListRowElem.mediumElem {
    flex: 1.2;
}

.dashboardListHeaderElem.shortElem, .dashboardListRowElem.shortElem {
    flex: 0.8;
}

.dashboardListHeaderElem.veryShortElem, .dashboardListRowElem.veryShortElem {
    flex: 0.5;
}

.subtitleLabel {
    font-weight: bold;
    margin-right: 5px;
}

.actionItem {
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    font-weight: bold;
}

.dashboardMainIcon {
    margin-bottom: -2px;
    width: 14px;
    height: 14px;
    align-self: center;
}

/* Modal windows */
.modal {
    display: block;
    position: fixed;
    top: 30%;
    bottom: 25%;
    left: 23%;
    right: 23%;
    width: auto;
    height: auto;
    /*height: 40%;*/
    align-self: center;
    /*margin: auto;*/
    background: white;
    border: 3px solid #767271;
    transition: 1.1s ease-out;
    box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
}

.modalContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #fff;
    /*padding-top: 30px;
    padding-bottom: 30px;*/

    /*border-radius: 5px;
    padding: 20px;*/
}

.modalMessage {
    font-weight: bold;
    font-size: 1.5rem;
    color: #767271;
}

.modelItem {

}

.modalLabel {
    font-weight: bold;
    margin-right: 5px;
}

.modalButtons {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* Button inside modalButtons */
.modalButtons button {
    flex: 1;
    font-weight: bold;
    margin: 10px;
    
}

.modalButtons button:hover {

}

.modalButton {
    flex: none;
    margin-top: 0px;
    margin-bottom: 0px;
}

.modalExceptions {
    max-width: 80%;
}

.whiteSpace {
    white-space: pre;
}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 900px) {
    .main {
        margin-left: 6rem;
    }
}

@media only screen and (max-width: 600px) {
    .main {
        padding: 0;
    }
}
