body {
    
}
  
.tabbed-code-block {
    font-family: 'Inter', sans-serif;
    background-color: #fff;
    color: white;
    margin-top: 24px;
    margin-right: 24spx;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 18px;
    line-height: 27px;
    font-weight: normal;
    text-align: left;
}

.tabbed-header {
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
}

.tab-links {
    display: flex;
    width: 100%; 
    background-color: #414042;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.tab-link {
    padding: 10px;
    cursor: pointer;
    width: 100px;
    text-align: center;
    background-color: #414042;
    color: #fff;
    border: none;
}

.left-tab {
    border-top-left-radius: 6px;
}

.tab-link.active {
    background-color: #000;
    color: #fff;
}

.tabbed-content {
    display: flex;
    margin-top: 0px;
    border-top: 1px solid #fff;
}

pre {
    margin: 0;
}

.code-snippet {
    display: none;
    padding: 10px;
    padding-top: 24px;
    padding-bottom: 24px;
    width: 100%;
    overflow: auto;
    margin-left: 20px;
    position: relative;
    background-color: #000;
    color: #ffff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.code-snippet.active  {
    display: block;
}

.copy-btn {
    background-color: #E14049;
    color: white;
    cursor: pointer;
    margin-top: 10px;
    padding: 5px 10px;
    font-size: 12px;
    float: right;
    position: absolute;
    top: 10px;
    right: 10px;
    border: 1px solid white;
}

.copy-btn:hover {
    background-color: #fff;
    color: #E14049;
}

.copy-btn.selected {
    content: "Code copied!";
}

