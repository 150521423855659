.buttonContainer {
    flex: 1;
    margin-top: 24px;
    margin-bottom: 24px;
}

button {
    border-radius: 4px;
    /*background: #AA3939;*/
    padding: 8px 16px; /*10px 22px;*/
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    /*transition: all 0.2s ease-in-out;*/
    text-decoration: none;
    /*white-space: nowrap;*/
    font-size: 20px;
    border: 1px solid #000;
}

button:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #000; /*#AA3939;*/
    border-color: #000;
}

.button:disabled {
    opacity: 0.5;
}

.secondaryButton {
    background: #fff;
    color: #d33438;
    border: 1px solid #d33438;
}

.secondaryButton:hover {
    background: #d33438;
    color: #fff;
    border: 1px solid #d33438;
}


/* Optional styles, inserted via props.buttonStyle */

.padded {
    padding: 20px 60px;
}

.whiteBlueBorder {
    color: #256ce1;
    border: 1px solid #256ce1;
    background-color: #fff;
}

.whiteBlueBorder:hover {
    color: #fff;
    background-color: #256ce1;
}


/* Optional styles, inserted via props.textStyle */

.huge {
    padding: 66px 12px;
    font-size: 26px;
}

.bold {
    font-weight: bold;
}
