 
.formFirstRow {
  margin-top: 36px;
}

.contactButton {
  margin-top: 48px;
}

.contactFromTextInputField {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
  
.contactFromTextInputField .inputFieldLabel {
    flex: 2;
}

.contactFromTextInputField .inputFieldTextInput {
    flex: 3;
}

.contactFromTextInputField .inputFieldReadOnlyText {
    flex: 3;
}

.contactMessage {
    margin-top: 6px;
    border-color: rgb(204, 204, 204);
    flex: 3;
}

/*.textArea {
    padding: 12px 20px;
}*/