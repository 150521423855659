.downloadContainer {
    overflow: hidden;
    margin-top: 40px;
    margin-bottom: 100px;
    text-align: center;
  }

.downloadContainer ol li {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

.downloadExplainer {
    margin-top: 60px;
}

.downloadSections {
    display: flex;
    flex-direction: row;
    gap: 64px;
}

.downloadColumn {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
}

.downloadButtonExplainer {
    margin-top: 40px;
}

.downloadNoteExplainer {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
}