.changePasswordModal {
    top: 18%;
    bottom: 22%;
}

.playerDataModal {
    top: 15%;
}

.changePasswordButton {
    padding: 0.5rem 2rem;
    min-width: 210px;
}

.changePasswordButtons {
    margin-top: 0;
}

.deleteButton, .disableButton, .enableButton {
    padding: 0.5rem 2rem;
    min-width: 140px;
}

.playerDataModalButton {
    margin-top: 15px;
}