.mainSection {
  margin: 110px 0;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #000;
  width: 100%;
  margin-top: 8rem;
}

.heroLogo {
  text-align: center;
  max-width: 500px;
  max-height: fit-content;
  margin-bottom: 2rem;

}

.heroLogoImageHome {
  height: 210px;
  width: 210px;
  max-width: 400px;
}

.features {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 2rem;
}

.feature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.featureImage {
  width: 200px;
  height: 200px;
  max-width: 200px;
  max-height: 200px;
}

.dashboardLink {
  max-width: 600px;
  text-align: center;
  margin: 5rem auto;
}

.studioWork {
  padding: 4rem 0;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0 auto;
}

.studioWorkTitle {
  text-align: center;
  margin-bottom: 2rem;
}

.studioWorkContents {
  margin-top: 1rem;
  width: 60%;
}

.studioWorkContents ul {
  text-align: left;
}

@media only screen and (max-width: 800px) {

  .mainSection {
    margin: 70px 0;
  }

  .features {
    flex-direction: column;
  }

  .feature {
    margin-bottom: 4rem;
    flex: 100%
  }
}


/**

.swpage {
  min-height:100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
}

.Home .lander {
  padding: 10px 0px;
  text-align: center;
  margin-bottom: 0px;
}

.Home .lander h1 {
  font-family: "SoleilBk", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #595959; 
}

.main-title {
  margin-top: 170px;
  margin-bottom: 30px !important;
}

.mainSection {
  margin-bottom: 60px;
}

.get-started-bttn {
  margin-top: 15px;
  margin-bottom: 75px;
  font-size: 24px;
}

.features {
  display: flex;
  flex-direction: row;
}

.feature {
  flex: 50%;
}

@media only screen and (max-width: 600px) {

  .features {
    flex-direction: column;
  }

  .feature {
    flex: 100%
  }
}

/**
.sw-button {
  background: url({process.env.PUBLIC_URL + "/getstarted-button.png"}) no-repeat;
  width: 160px;
  height: 120px;
  display: inline-block;
}

.sw-button:hover {
  background: url("/getstarted-button-hover.png") no-repeat;
  width: 160px;
  height: 120px;
  display: inline-block;
}
*/
