.playerList {
    margin-left: 35px;
}

.playersTitle {
    margin-left: -30px;
}

.playerDataDownload {
    margin-top: 36px;
}