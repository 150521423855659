.termsContainer {
    margin-bottom: 100px;
  }

  .privacyContainer {
    margin-bottom: 100px;
  }

  .termsMain {
    margin-top: 40px;
  }

  .termsText {
    margin-top: 25px;
    margin-bottom: 25px;
  }