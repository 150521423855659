.dashboardFooter {
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    color: #000;
    margin-top: 14rem;
    /*margin-left: 4rem;
    margin-right: 4rem;*/
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding: 50px 36px;
    z-index: 5;
  }
  
  .footerContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  
  .footerTextContentItem {
     margin: 0 12px;
  }
  
  .footerLink {
    color: #000;
    text-decoration: none;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
  }
  
  .twitterLogo {
    width: 30px;
    height: 30px; 
    margin-top: 5px;
  }
  
  .footerCopyright {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
  }
  
  .footerLogo {
    width: 198px;
    height: 37.5px;
  }
  
  @media only screen and (max-width: 1000px) { 
  
  
    .dashboardFooter {
        flex-direction: column;
        gap: 1.5rem;
        margin-top: 2rem;
        padding: 30px 36px;
    }
  
    .footerContent {
        order: 1;
    }
  
    .footerCopyright{
        order: 3;
    }
  
    .footerLogoDiv {
        order: 2;
    }
  }


  /*
.dashboardFooter {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 16px;
    line-height: 1.5em;
    display: grid;
    grid-template-columns: 300px auto 50px;
    grid-template-rows: auto;
    background-color: #8b8383; 
    color: white;
}

.footer-left {
    grid-column: 1;
    grid-row: 1;
    padding-top: 5px;
    font-size: 12px;
}

.footer-right {
    grid-column: 3;
    grid-row: 1;
}
*/