nav {
    font-family: Inter;
    background: #fff;
}

.navList {
    margin: 0;
    padding: 1rem 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navItem {
    list-style: none;
    margin-right: 2rem;
}

.mobileNav, .navItem.itemLogoMobile, .navItem.hamburgerItem {
    display: none;
}

.navItem a {
    color: #000;
    text-decoration: none;
    font-size: 1rem;
    font-weight: normal;
} 

.navItem:first-child {
    margin-right: auto;
    margin-left: 2rem;
}

/* mobile logo, replaces main logo when screen size is small */
.navItem:nth-child(2) {
    margin-right: auto;
    margin-left: 2rem;
}

.btnPrimary {
    font-family: Inter;
    font-size: 1rem;
    border-radius: 6px;
    background-color: #000;
    color: #fff;
    border: 1px solid #000;
    outline: none;
    padding: 0.5rem 2rem;
    cursor: pointer;
}

.btnPrimary:hover {
    background-color: #fff;
    color: #000;
}

.chevron {
    margin-left: 0.25rem;
}

.featureOverlay {
    position: absolute;
    min-width: 120px;
    font-size: 16px;
    top: 78px;
    z-index: 1;
    border-radius: 2px;
}

.featuresOverlayElement {
    margin-top: 3px;
    padding: 1rem 1rem;
    color: #d33438;
    background-color: #fff;
    border: 1px solid #d33438;
    border-radius: 3px;
    cursor: pointer;
}

.gameSwitcher {
    border-radius: 6px;
    background-color: #d33438;
    color: #fff;
    border: 1px solid #d33438;
    outline: none;
    padding: 0rem 0.5rem;
    /*cursor: pointer;*/
}

nav hr {
    margin: 0;
    border: none;
    border-bottom: 1px solid #A4A9C3;
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    width: auto;
}

/* Navbar media queries: 700, 1000, 1200, 1700, 2000, 2300 */

@media only screen and (max-width: 2000px) {

    .navItem.contactItem {
      display: none;
    }
}

@media only screen and (max-width: 1400px) {

    .navItem.featureItem, .featureOverlay {
        display: none;
    }

    nav hr {
        display: none;
    }
}

@media only screen and (max-width: 1200px) {

    .navItem.downloadItem {
      display: none;
    }
    
}

@media only screen and (max-width: 1000px) {

    .navItem.changePwdItem {
        display: none;
      }

    .navItem.createNewGameItem  {
        display: none;
    } 
}

@media only screen and (max-width: 850px) {

    .navItem.gameSwitcher {
        display: none;
    } 
}

@media only screen and (max-width: 700px) {

    .navItem.itemLogo, .navItem.signInItem {
        display: none;
    }

    .navItem.itemLogoMobile {
        display: list-item;
    } 

    .navItem.hamburgerItem {
        display: block;
        cursor: pointer;
    }

    .mobileNav {
        display: block;
        z-index: 2;
    }

    .mobileNavHeader {
        background: #fff;
        border-bottom: 2px solid #26212E;
    }

    .mobileNavList {
        margin: 0;
        padding: 1rem 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .mobileNavItem {
        list-style: none;
        margin-right: 2rem;
    }
    
    .mobileNavItem:first-child {
        margin-right: auto;
        margin-left: 2rem;
    }

    .mobileNavContent {
        background-color: #d33438;
        padding-bottom: 3rem;
    }

    .mobileContentNavList {
        margin: 0;
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mobileContentNavItem {
        list-style: none;
        margin-top: 2rem;
    }

    .mobileContentNavItem a {
        color: #fff;
        text-decoration: none;
        font-size: 33px;
        line-height: 40px;
        font-weight: normal;
    } 

    .mobileContentNavItem.itemLogoMobile {
        margin-bottom: 2.5rem;
    }

    .mobileContentNavItem.signInItem {
        margin-top: 3rem;
    }

    .btnPrimary {
        font-size: 33px;
        line-height: 40px;
        font-weight: normal;
    }

}

@media only screen and (min-width: 701px) {

    nav {
        display: block;
    }

    .mobileNav {
      display: none;
    }

    .mobileNavHeader {
        display: none;
    }

    .mobileNavContent {
        display: none;
    }
}