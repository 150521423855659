.troubleshootingContainer {
    overflow: hidden;
    text-align: center;
    width: 750px;
    margin-top: 80px;
    margin-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .troubleshootingTitle {
    margin-top: 60px;
  }
  
  .troubleshootingExplainer {
    margin-top: 30px;
  }