.sidebarElement {
    /*width: 92%;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    font-style: normal;
    text-decoration: none;
    border-radius: 6px;
    border-color: #fff;
    background-color: #595959; /* #000; */ 
    padding: 8px 24px;
}

.sidebarElement.active {
    background-color: #fff;
    border: 1px solid #595959; /* #000; */
}

.sidebarIcon {
    height: 24px;
    width: 24px;
    color: #fff;
    margin-top: 4px;
}

.sidebarIcon.active {
    color: #595959; /* #000; */
}

.sidebarText {
    size: 18px;
    font-weight: 700;
    font-style: normal;
    /*margin-left: 12px;*/
    padding-top: 4px;
    color: #fff;
    text-align: center;
}

.sidebarText.active {
    color: #595959; /* #000; */
}

@media only screen and (max-width: 900px) {
    .sidebarText {
      display: none;
    }
}

@media only screen and (max-width: 1200px) {

}
