.enabled {
    color: green;
}

.disabled {
    color: red;
}

.confirmed {
    color: blue;
}

.unconfirmed, .resetRequired, .forceChangePassword {
    color: orange;
}

.unknown {
    color: gray;
}