.footer {
  /*position: absolute;
  bottom: 0;*/
  background: #fff;
  color: #000;
  margin-top: 2rem;
  margin-left: 4rem;
  margin-right: 4rem;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 50px 36px;
}

.footerContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.footerTextContentItem {
   margin: 0 12px;
}

.footerLink {
  color: #000;
  text-decoration: none;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
}

.twitterLogo {
  width: 30px;
  height: 30px; 
  margin-top: 5px;
}

.footerCopyright {
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
}

.footerLogo {
  width: 198px;
  height: 37.5px;
}

@media only screen and (max-width: 1000px) { 


  .footer {
      flex-direction: column;
      gap: 1.5rem;
      margin-top: 2rem;
      padding: 30px 36px;
  }

  .footerContent {
      order: 1;
  }

  .footerCopyright{
      order: 3;
  }

  .footerLogoDiv {
      order: 2;
  }
}