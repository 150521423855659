.sidebar {
    background-color: #fff;
    position: absolute;
    left: 0;
    width: 8em;
    padding-top: 24px;
    padding-left: 47px;
    padding-right: 47px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    z-index: 1;
    overflow: hidden;
}

a.sidebarLink {
  outline: none;
  text-decoration: none;
  color: #000; /*#AAA5A5;*/
}

a.sidebarLink:active {
  color: #264FE1;
}

@media only screen and (max-width: 900px) {
    .sidebar {
      width: 6em;
      padding-left: 35px;
    }
}

@media only screen and (max-width: 1200px) {

}
