html {
    overflow: hidden;
}

.dashboardTopWrap {
    height: 100%;
}

.fixedHeader {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
}

.dashboard {
    background-color: #F6F8FA;
    height: 100%;
}

.dashboardMain {
   height: 100%;
}