.newGameContainer {
    margin-top: 40px;
    margin-bottom: 80px;
}
  
.newGameUnauth {
    margin-top: 300px;
    margin-bottom: 300px;
}

.preContent {
    padding-left: 48px;
}