.ldboardElem {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.elemName {
  font-weight: bold;
}

.elemName {
  /* alone on its line, centered on the left hand side */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  width: 100%;
  text-align: left;
}

.ldboardConfig {
  /* The config section */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.ldboardConfigCol {
  /* The config section */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin-bottom: 10px;
  padding: 10px 40px;
}

.ldboardConfigElem {
  /* Two per row */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50%;
}

.ldboardScores {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

.noScores {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ldboardScoresHeader, .ldboardScoresRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.ldboardScoresHeaderElem {
  flex: 1;
  background-color: #767271;
  color: #fff;
  font-weight: bold;
  border: 1px solid #fff;
}

.ldboardScoresElem {
  flex: 1;
  border: 1px solid #767271;
}

.scoreElem {
  align-self: center;
}