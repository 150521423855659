.changePasswordContainer {
    margin-top: 80px;
}

.signinContainer {
    overflow: hidden;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    margin-top: 20px;
    margin-bottom: 100px;
  }

  .signup {
    grid-column: 1;
    grid-row: 1;
  }
  
  .login {
    grid-column: 2;
    grid-row: 1;
    margin-top: 40px;
  }
  
  .emailPassword {
    margin-top: 100px;
    /*padding-left: 180px;*/
  }

  .forgotPasswordContainer {
    /*margin-top: 120px;*/
  }

  .forgotPwdForm {
    margin-top: 20px;
  }
  
  .forgotPwdConfForm {
    margin-top: 20px;
  }
  
  .forgotPwdTitle {
    margin-top: 120px;
    padding-left: 0px;
  }
  
  .right-margined {
    margin-right: 85px;
  }

  @media only screen and (max-width: 1200px) {

    .signinContainer {
      grid-template-columns: 100%;
    }
  
    .signup {
      grid-column: 1;
      grid-row: 1;
      margin-top: 90px;
      margin-bottom: 90px;
      padding-left: 5px;
      padding-right: 5px;
    }
  
    .login {
      grid-column: 1;
      grid-row: 1;
      margin-top: 10px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }