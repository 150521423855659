.gameSwitcherSelect {
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    font-family: Inter;
    font-size: 1rem;
    border-radius: 6px;
    background-color: #d33438;
    color: #fff;
    border: 1px solid #d33438;
    outline: none;
    padding: 0.25rem;
    cursor: pointer;
}

@media only screen and (max-width: 700px) {

    .gameSwitcherSelect {
        font-size: 2rem;
        padding: 0.25rem 0.25rem;
        background-color: #fff;
        color: #d33438;
        border: 1px solid #fff;
    }

}